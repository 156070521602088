import React from 'react';

import { IMicroCopy, IResourceType } from '../types/types';

export const PageContext = React.createContext<{
  resourceTypes?: IResourceType[];
  microCopy?: IMicroCopy;
  [key: string]: any;
}>({});

const PageContextProvider = ({ children, value }) => {
  return (
    <PageContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContext;
export { PageContextProvider };
