import { useMediaQuery } from 'react-responsive';

import { defaultTheme } from './themes';

export const desktopOnly = `@media (min-width: ${defaultTheme.breakpoints.desktop})`;

export const tabletOnly = `@media (min-width: ${defaultTheme.breakpoints.tablet})`;

export const useIsDesktop = () =>
  useMediaQuery({
    query: `(min-width: ${defaultTheme.breakpoints.desktop})`,
  });
