import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';

import fontGet from './fontGet';
import themeGet from './themeGet';

import '../fonts/fonts.css';
import { desktopOnly } from './desktopHelpers';

const GlobalStyle = createGlobalStyle`
  ${normalize()}
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    font-size: 62.5%;
    box-sizing: border-box;
    font-family: ${themeGet('font.family')};
    font-weight: ${themeGet('font.weight.normal')};
    font-style: normal;
    color: ${themeGet('color.textDefault')};
    --background: ${themeGet('color.background.softWhite')};
    --primary: ${themeGet('color.primary.graphite')};
    --highlight: ${themeGet('color.primary.graphite')};

    
  }
  html, body, #___gatsby, #gatsby-focus-wrapper {
    min-height: 100%;
  }
  body {
    ${fontGet('b1')};
  }
  html &[type="button"] {
    appearance: none;
  }

  .styled-rich-text {
    a {
      color: var(--highlight);
      cursor: pointer;
      word-break: break-word;
    }
  }

  .rich-text-ul {
    list-style-type: disc; 
    list-style-position: inside;

    ul {
      margin-top:16px;
      list-style-type: circle;
      list-style-position: inside;
    }
  }

  .async-hide {
    opacity: 0 !important
  }

  .rich-text-ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin-left: 34px;

    ol {
      margin-top:16px;
      list-style-position: outside;
      list-style-type: lower-alpha;
    }
  }

  #main-content {
    padding-top: 65px;

    ${desktopOnly} {
      padding-top: 0;
    }
  }

  #onetrust-consent-sdk {
    pointer-events: all;
  }
`;

export default GlobalStyle;
