import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  breakpoints: {
    desktopLarge: '1200px',
    desktop: '1007px',
    tablet: '768px',
    mobile: '480px',
  },
  color: {
    textDefault: '#333333',
    textInactive: '#666666',
    primary: {
      yellow: '#FDF361',
      graphite: '#12171D',
      white: '#F2F2F0',
      powerPurple: '#2D1843',
      deepBlue: '#1a1e24',
      pink: '#BC0071',
    },
    secondary: {
      lightYellow: '#FFF8C5',
      pink: '#FF9FBF',
      salmon: '#FF5F4A',
      midnightBlue: '#0C2354',
      lightBlue: '#8DF7FF',
      alert: '#D60000',
      state: '#D2D2D3',
      softWhite: '#F3F3F3',
      teal: '#01AFAD',
    },
    background: {
      softWhite: '#F2F2F0',
      lightBeige: '#E8E8DB',
      stone: '#C8C0AD',
    },
  },
  font: {
    family:
      '"Grotesque-Pro", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"',
    weight: {
      normal: '400',
      bold: '700',
    },
    lineHeight: {
      h1: '12rem',
      h1sm: '8rem',
      h2: '5.4rem',
      h3: '3.6rem',
      h4: '2.9rem',
      b2: '2.5rem',
      b2c: '3.2rem',
      b1: '1.9rem',
      caption: '1.9rem',
    },
    size: {
      h1: '12rem',
      h1sm: '8rem',
      h2: '5.4rem',
      h3: '3.6rem',
      h4: '2.4rem',
      b2: '2.1rem',
      b2c: '2.1rem',
      b1: '1.6rem',
      caption: '1.6rem',
    },
    mobile: {
      lineHeight: {
        h1: '6rem',
        h1sm: '5rem',
        h2: '4rem',
        h3: '3.2rem',
        h4: '2.5rem',
        b2: '2.2rem',
        b2c: '2.7rem',
        b1: '1.9rem',
        caption: '1.9rem',
      },
      size: {
        h1: '6rem',
        h1sm: '5rem',
        h2: '4rem',
        h3: '3.2rem',
        h4: '2.14rem',
        b2: '1.8rem',
        b2c: '1.8rem',
        b1: '1.6rem',
        caption: '1.6rem',
      },
    },
  },
  spacing: {
    s1: '96px',
    s2: '80px',
    s3: '56px',
    s4: '48px',
    s5: '32px',
    s6: '24px',
    mobile: {
      s1: '56px',
      s2: '48px',
      s3: '40px',
      s4: '32px',
      s5: '24px',
      s6: '16px',
    },
  },
};

export default defaultTheme;
