import { get } from '@styled-system/core';
import { DefaultTheme } from 'styled-components';

import { Leaves } from '../utils/typeUtils';

type Props = { theme: DefaultTheme };

/**
 * Implementation of ThemeGet
 * @param path: Path to drill to specific theme leaf
 * @returns
 */
function themeGet(
  path: Leaves<DefaultTheme>,
): ({ theme }: Props) => string | number | undefined {
  return ({ theme }: Props) => theme && get(theme, path);
}

export default themeGet;
