import { css } from 'styled-components';

import { desktopOnly } from './desktopHelpers';
import themeGet from './themeGet';

type Props = {
  size: 'h1' | 'h1sm' | 'h2' | 'h3' | 'h4' | 'b1' | 'b2' | 'b2c' | 'caption';
};

const fontGet = (size: Props['size']) => css`
  ${size === 'caption' ? 'font-weight: bold' : 'font-weight: normal'};
  font-size: ${themeGet(`font.mobile.size.${size}`)};
  line-height: ${themeGet(`font.mobile.lineHeight.${size}`)};

  ${desktopOnly} {
    font-size: ${themeGet(`font.size.${size}`)};
    line-height: ${themeGet(`font.lineHeight.${size}`)};
  }
`;

export default fontGet;
