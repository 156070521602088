import React from 'react';

import type { GatsbyBrowser } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { Context as ResponsiveContext } from 'react-responsive';
import { ThemeProvider } from 'styled-components';

import { PageContext } from '../hooks/pageContextProvider';
import GlobalStyle from '../style/global';
import { defaultTheme } from '../style/themes';
import { IMicroCopy, IResourceType } from '../types/types';

declare global {
  interface Window {
    OneTrust: any;
    goToHomePage: () => void;
    goBack: () => void;
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const microCopy = props?.data?.allContentfulContentMicrocopy
    ?.nodes?.[0] as IMicroCopy;
  const resourceTypes = props?.data?.allContentfulContentResourceType
    ?.nodes as IResourceType[];
  return (
    <ThemeProvider theme={defaultTheme}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_RECAPTCHA_CLIENT}
      >
        <Helmet>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script={process.env.GATSBY_ONE_TRUST_DOMAIN}
            data-document-language="true"
          />
        </Helmet>

        <PageContext.Provider
          value={{ ...props.pageContext, microCopy, resourceTypes }}
        >
          <GlobalStyle />
          <ResponsiveContext.Provider
            value={{ width: defaultTheme.breakpoints.desktop }}
          >
            {element}
          </ResponsiveContext.Provider>
        </PageContext.Provider>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
};

// Functions specifically for running OneTrust cookie scripts on SPAs.
// Adapted to work properly.
// cf. https://community.cookiepro.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046?language=en_US

const reloadOTBanner = (locale: string) => {
  const otConsentSdk = document.getElementById('onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }

  if (window && window.OneTrust) {
    window.OneTrust.Init();

    setTimeout(function () {
      window.OneTrust.changeLanguage(locale);
      window.OneTrust.LoadBanner();
    }, 1000);
  }
};

const enhanceCookieSettingsButton = () => {
  const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');
  for (let i = 0; i < toggleDisplay.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    toggleDisplay[i].addEventListener('click', (event) => {
      event.preventDefault();
      if (window && window.OneTrust) {
        window?.OneTrust?.ToggleInfoDisplay();
      }
    });
  }
};

const generateCookieListPolicy = () => {
  if (window && window.OneTrust) {
    window.OneTrust.initializeCookiePolicyHtml();
  }
};

const locales = [
  'en-us',
  'en-gb',
  'fr-fr',
  'de-de',
  'it-it',
  'es-es' /*, 'ja-jp'*/,
];
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = (props) => {
  // due to the OneTrust cookie script only loading on first visit,
  // we need to trigger some specific scripts when visiting the Cookie Policy
  // and Cookie List pages
  const pathLocale = props.location?.pathname?.split('/')?.[1];
  const locale = locales.includes(pathLocale) ? pathLocale : locales[0];
  reloadOTBanner(locale);

  if (props.location.pathname.indexOf('cookie-list') > -1) {
    generateCookieListPolicy();
  }

  if (props.location.pathname.indexOf('cookie-policy') > -1) {
    enhanceCookieSettingsButton();
  }
};
